<template>
  <a-layout class="fit">
    <a-layout-header ref="header" style="height: 40px; z-index: 1; line-height: inherit; border-bottom: 1px solid #f0f0f0; box-shadow: none">
      <div class="flex items-center full-height">
        <div class="v-ml-auto">{{ userinfo.realName }}，您好！欢迎来到千禾社区。</div>
        <span class="hover-line text-indigo v-mx-sm" @click="onChangePwd">修改密码</span>
        <span class="hover-line text-indigo v-mr-sm" @click="logout">退出社区</span>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="186">
        <a-menu :selected-keys="selectedKeys" :default-open-keys="navMenus.map(menu => menu.path)" mode="inline" theme="dark" @click="onMenuSelect">
          <template v-for="menu in navMenus">
            <a-sub-menu v-if="menu.children && menu.children.length > 0" :key="menu.path">
              <span slot="title">
                <!-- <a-icon :type="menu.icon || 'menu'" /> -->
                <span>{{ menu.name }}</span>
              </span>
              <a-menu-item v-for="submenu in menu.children" :key="submenu.path">{{ submenu.name }}</a-menu-item>
            </a-sub-menu>
            <a-menu-item :key="menu.path" v-else>
              <a-icon :type="menu.icon || 'menu'" />
              <span>{{ menu.name }}</span>
            </a-menu-item>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout-content>
        <div class="page-wrapper" ref="container" style="height: calc(100vh - 120px)">
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 修改密码 -->
    <a-modal :confirmLoading="confirmUpdating" :visible="showPwdModal" :width="400" @cancel="cancelChangePwd" @ok="handleSubmit" centered okText="确认" title="修改密码">
      <a-form autocomplete="off">
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <span slot="label">
            <i class="required-mark">*</i>
            原密码
          </span>
          <a-input type="password" v-model="oldpwd" ref="oldpwd" autoComplete="new-password" />
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <span slot="label">
            <i class="required-mark">*</i>
            新密码
          </span>
          <a-input type="password" v-model="newpwd1" ref="newpwd1" autoComplete="new-password" />
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <span slot="label">
            <i class="required-mark">*</i>
            确认密码
          </span>
          <a-input type="password" v-model="newpwd2" ref="newpwd2" autoComplete="new-password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script>
import { changePwd } from '@/api/user'
export default {
  name: 'UserCenter',
  data() {
    return {
      navMenus: [
        {
          name: '首页',
          path: '/usercenter/dashboard',
          icon: 'calendar',
        },
        {
          name: '项目管理',
          path: '/usercenter/project',
          icon: 'appstore',
        },
        {
          name: '项目文档',
          path: '/usercenter/document',
          icon: 'file-text',
        },
        {
          name: '用户信息',
          path: '/usercenter/profile',
          icon: 'user',
        },
      ],
      selectedKeys: [],
      oldpwd: '',
      newpwd1: '',
      newpwd2: '',
      showPwdModal: false,
      confirmUpdating: false,
    }
  },
  watch: {
    $route(route) {
      console.log('route change', route.path)

      window.route = this.$route
      this.findSelectedKey()
      // this.routeIdx = this.routes.findIndex((r) => r.path === route.matched[0].path)
    },
  },
  methods: {
    findSelectedKey() {
      let menu = this.navMenus.find(item => this.$route.fullPath.startsWith(item.path))
      this.selectedKeys = [menu.path]
    },
    onMenuSelect({ item, key, keyPath }) {
      console.log(item, key, keyPath)
      this.selectedKeys = [key]
      this.$router.push(key)
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    onChangePwd() {
      this.oldpwd = ''
      this.newpwd1 = ''
      this.newpwd2 = ''
      this.showPwdModal = true
    },
    cancelChangePwd() {
      this.showPwdModal = false
    },
    handleSubmit() {
      let vm = this
      this.$message.destroy()
      if (this.oldpwd === '') {
        this.$message.warning('请输入原密码')
        this.$refs.oldpwd.focus()
        return
      }
      if (this.newpwd1 === '') {
        this.$message.warning('请输入新密码')
        this.$refs.newpwd1.focus()
        return
      }
      if (this.newpwd2 === '') {
        this.$message.warning('请输入确认密码')
        this.$refs.newpwd2.focus()
        return
      }
      if (this.newpwd1 !== this.newpwd2) {
        this.$message.warning('新密码与确认密码不一致')
        return
      }
      this.confirmUpdating = true
      let oldPwd = this.oldpwd
      let newPwd = this.newpwd1
      // let oldpwd = CryptoJS.MD5(this.user.username + this.oldpwd).toString()
      // let newpwd = CryptoJS.MD5(this.user.username + this.newpwd1).toString()
      changePwd({
        userId: this.userinfo.id,
        oldPwd,
        newPwd,
      })
        .then(({ data }) => {
          this.confirmUpdating = false
          if (data.success) {
            this.showPwdModal = false
            this.$success({
              title: '提示',
              content: '密码修改成功，请重新登录。',
              onOk: () => {
                vm.$store.dispatch('logout')
                vm.$router.push('/login')
              },
            })
          } else {
            this.$message.warning(data.msg)
          }
        })
        .catch(() => {
          this.$message.warning('服务器响应异常')
          this.confirmUpdating = false
        })
    },
  },
  mounted() {
    console.log(this.$route)
    this.findSelectedKey()
  },
}
</script>
